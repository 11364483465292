import images from './images';

const antipasti = [
  {
    title: 'Tagliere di salumi e formaggi zona',
    price: '€16',
    tags: '(Selection of salami and cheese of Basilicata)',
  },
  {
    title: 'Tris di fomaggi con confettura home made ',
    price: '€10',
    tags: '(Mix local cheese with jam home made)',
  },
  {
    title: 'Cialledda materana',
    price: '€5',
    tags: '(Local specially: soaked bread, tomatoes, oil, oregano)',
  },
  /*{
    title: 'Pepereni cruschi di Senise ',
    price: '$6',
    tags: '(Typical fried peppers)',
  },
  {
    title: 'Caprese',
    price: '$5',
    tags: '(Mozzarella cheese, tomato, oil, basil)',
  },*/
];

const primi = [
  {
    title: 'Orecchiette con cime di rapa e peperene crusco',
    price: '€9',
    tags: '(Orecchiette with Broccoli Rabe and Crispy Peppers)',
  },
  {
    title: 'Cavatelli salsiccia pezzente e cardencelli',
    price: '€9',
    tags: '(Fresh pasta with sausage and mushrooms)',
  },
  {
    title: 'Scialatielli con crema di zucca, burrata, capocollo croccante e olive nere',
    price: '€10',
    tags: '(Scialatielli with cream of pumpkin burrata,crispy bacon)',
  },
  {
    title: 'Ravioli ipieni di nicotta con pomodero salvia e buccia di limone',
    price: '€10',
    tags: '(Ravioli with tomatoes sauce, ricotta, lemon peel)',
  },
  {
    title: 'Orecchiette, al ragü materano',
    price: '€12',
    tags: '(Agnello, vitello, maiale)',
  },
];

const secondi = [
  {
    title: 'Tagliata con cardencelli e crusco',
    price: '€18',
    tags: '(Sliced Steak with King Oyster Mushrooms and Crispy Peppers)',
  },
  {
    title: 'Costata di Maiale alla griglia con misticanza di verdure',
    price: '€14',
    tags: '(Fresh pasta with sausage and mushrooms)',
  },
  {
    title: "Filetto con crema all'aglianico",
    price: '€19',
    tags: '(Beef Tenderloin with Aglianico Wine Cream)',
  },

];


const dolci = [
  {
    title: 'Tette della monaca con chrema chattily e pistacchio',
    price: '€6',
    tags: "(Nun's Breasts with Chantilly Cream and Pistachio)",
  },
  {
    title: 'Tiramisù classico/ pistacchio',
    price: '€5',
    tags: ' ',
  },
  {
    title: "Pannacotta al crusco con crème caramel",
    price: '€5',
    tags: ' ',
  },
  {
        title: "Sporcamuss con crema pasticceria",
        price: '€5',
        tags: ' ',
      },

];
const cocktails = [
  {
    title: "Coca Cola / Fanta",
    price: '€3',
    tags: '',
  },
  {
    title: 'Chinotto lurisia',
    price: '€5',
    tags: ' ',
  },
  {
    title: 'Aperitivo lurisia',
    price: '€5',
    tags: '',
  },

];

const awards = [
{
    imgUrl: images.award01,
    title: 'Rising Star',
  },
  {
    imgUrl: images.award02,
    title: 'Bib Gourmond',
  },
  {
      imgUrl: images.award03,
      title: 'Outstanding Chef',
    },

  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
  },

];

const menuList=
{
antipasti,
primi,
secondi,
dolci,
cocktails,
awards
};


export default menuList;
