import React from "react";

import { SubHeading, MenuItem } from "../../components";
import { data, images } from "../../constants";
import "./SpecialMenu.css";

// Лучше перезагрузить локал хост для тяги данных

const SpecialMenu = () => (
  <div className="app__specialMenu flex__center section__padding" id="menu">
    <div className="app__specialMenu-title">
      <SubHeading title="Menu that fits your palette" />
      <h1 className="headtext__cormorant">Today&apos;s Special</h1>
    </div>

    <div className="app__specialMenu-menu">
      <div className="app__specialMenu-menu_wine  flex__center">
        <p className="app__specialMenu-menu_heading">Antipasti (Appetizers)</p>
        <div className="app__specialMenu_menu_items">
          {data.antipasti.map((antipasti, index) => (
            <MenuItem
              key={antipasti.title + index}
              title={antipasti.title}
              price={antipasti.price}
              tags={antipasti.tags}
            />
          ))}
        </div>
         <p className="app__specialMenu-menu_heading">Primi</p>
                <div className="app__specialMenu_menu_items">
                  {data.primi.map((primi, index) => (
                    <MenuItem
                      key={primi.title + index}
                      title={primi.title}
                      price={primi.price}
                      tags={primi.tags}
                    />
                  ))}
           </div>
      </div>

      <div className="app__specialMenu-menu_img">
        <img src={images.menu} alt="menu__img" />
      </div>

      <div className="app__specialMenu-menu_cocktails  flex__center">
        <p className="app__specialMenu-menu_heading">Secondi</p>
        <div className="app__specialMenu_menu_items">
          {data.secondi.map((secondi, index) => (
            <MenuItem
              key={secondi.title + index}
              title={secondi.title}
              price={secondi.price}
              tags={secondi.tags}
            />
          ))}
        </div>
        <p className="app__specialMenu-menu_heading">Dolci</p>
                <div className="app__specialMenu_menu_items">
                  {data.dolci.map((dolci, index) => (
                    <MenuItem
                      key={dolci.title + index}
                      title={dolci.title}
                      price={dolci.price}
                      tags={dolci.tags}
                    />
                  ))}
                  </div>
         <p className="app__specialMenu-menu_heading">Bevande</p>
         <div className="app__specialMenu_menu_items">
                                             {data.cocktails.map((cocktails, index) => (
                                               <MenuItem
                                                 key={cocktails.title + index}
                                                 title={cocktails.title}
                                                 price={cocktails.price}
                                                 tags={cocktails.tags}
                                               />
                                             ))}
                                             </div>
      </div>
    </div>

  {/*   <div style={{ marginTop: 15 }}>
      <button type="button" className="custom__button">
        View More
      </button>
    </div> */}
  </div>
);

export default SpecialMenu;
